import { WalletTypes } from "utils/enums";

// Список ПС, которые парсятся
export const parsingPs = [
	WalletTypes.BKASH,
	WalletTypes.CLICK_UZS,
	WalletTypes.NAGAD,
	WalletTypes.ROCKET,
	WalletTypes.VIETCOM_BANK,
	WalletTypes.UPI_HB,
	WalletTypes.UPI_IB,
	WalletTypes.UPI_W,
	WalletTypes.UPI_IDB,
	WalletTypes.UPI_IN,
	WalletTypes.UPI_HB,
	WalletTypes.IMPS_HB,
	WalletTypes.IMPS_IB,
	WalletTypes.IMPS_IDB,
	WalletTypes.JAZZCASH_BUSINESS,
];

/**
 * Проверяет, что переданная ПС находится в списке.
 *
 * @link https://confluence.fixmost.com/pages/viewpage.action?pageId=39478438
 * @param paymentSystem Тип платежной системы
 * @returns true, если система поддерживает парсинг
 */
export const isPSWithParsing = (paymentSystem: WalletTypes) => {
	const list = new Set(parsingPs);

	return list.has(paymentSystem);
};
