/* eslint-disable ternary/no-unreachable */
import { ElementType } from "@paykassma/pay-kit/lib/elements/PayKitForm/FormBuilder/types";
import { WalletPriority } from "api/walletGroup";
import { Requisite } from "api/walletTypesGroup";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { t, useTranslation } from "pay-kit";
import { ReactElement, useContext, useMemo } from "react";

import styles from "../CreateWalletModal.module.scss";
import { useCommonWalletFields } from "../hooks/useCommonWalletFields";
import { useSpecificWalletFields } from "../hooks/useSpecificWalletFields";
import { handleSuccessType } from "../index";
import { notLaterThanCreatedAt, notLaterThanToday } from "../utils/helpers";
import { isPSWithParsing } from "../utils/paymentSystems";
import { FieldTree } from "../utils/treeSort/tree";
import type { ProxyType } from "../utils/types";
import { isProviders, isProxyRequired, isRequired, maxLengthValidation, onlyDigitsAllowed } from "../utils/validators";

export const priorityMapping = {
	[WalletPriority.LOW]: t("Low"),
	[WalletPriority.BELOW_NORMAL]: t("Below normal"),
	[WalletPriority.NORMAL]: t("Normal"),
	[WalletPriority.ABOVE_NORMAL]: t("Above normal"),
	[WalletPriority.HIGH]: t("High"),
};

export const priorityMappingWithDescription = {
	[WalletPriority.LOW]: t("Priority: Low"),
	[WalletPriority.BELOW_NORMAL]: t("Priority: Below normal"),
	[WalletPriority.NORMAL]: t("Priority: Normal"),
	[WalletPriority.ABOVE_NORMAL]: t("Priority: Above normal"),
	[WalletPriority.HIGH]: t("Priority: High"),
};

export const priorityOptions = Object.keys(priorityMapping).map((priority) => {
	return { value: Number(priority), label: priorityMapping[Number(priority) as keyof typeof priorityMapping] };
});

export const useWalletData: UseWalletDataType = (
	isCreating,
	onSubmit,
	requisites,
	useProxy = false,
	proxiesList,
	formState,
	created_at,
	adminPanelType
) => {
	const { t } = useTranslation();

	const { walletTypes, isLoading: walletTypesLoading } = useContext(WalletTypesContext);
	const isInstance = adminPanelType === "instance";

	const walletTypesOptions = walletTypes
		.filter((walletType) => !walletType.is_settlement)
		.map((walletType) => ({
			value: walletType.code,
			label: walletType.name,
		}));

	const isP2PWalletSelected = (selectedWT?: string) =>
		selectedWT === undefined ? false : walletTypes.find((wt) => wt.code === selectedWT)?.payment_type === "P2P";

	const commonFields = useMemo(
		() => [
			{
				type: "Group",
				elements: [
					{
						label: t("Status"),
						type: "Switcher",
						name: "status",
						options: [
							{
								label: t(`Disabled`),
								value: `disabled`,
							},
							{
								label: t("Active"),
								value: `working`,
							},
						],
					},
				],
				existsIf: isInstance && isCreating,
				render: (children: readonly ReactElement[]) => (
					<div
						style={{
							marginBottom: "12px",
						}}
					>
						{children}
					</div>
				),
			},
			{
				label: t("Currency"),
				name: "currency_code",
				type: "Select",
				options: ({ wallet_type }: { readonly wallet_type: string }) => {
					if (adminPanelType === "instance") {
						return walletTypes
							.filter((wt) => wt.code === wallet_type)[0]
							?.supported_currencies.map((curr) => ({ label: curr, value: curr }));
					}

					if (adminPanelType === "superadmin") {
						return walletTypes
							.filter((wt) => wt.code === wallet_type)[0]
							?.currencies?.wallet.map((curr) => ({ label: curr, value: curr }));
					}

					return [];
				},
				isLoading: walletTypesLoading,
				disabled: ({ wallet_type, ...props }: { readonly wallet_type: string }) => {
					return wallet_type === undefined;
				},
				isRequired: true,
				validation: isRequired,
			},
			{
				label: t("Priority"),
				type: "Select",
				name: "priority",
				options: priorityOptions,
				isRequired: true,
				existsIf: isInstance,
				validation: [(value) => (value == null ? t("Field is required") : undefined)],
			},
			{
				name: "is_standard",
				label: t("Standard wallet"),
				type: "Toggler",
				// existsIf: isCreating,
			},
			{
				name: "comment",
				label: t("Comment"),
				type: "Textarea",
				existsIf: isCreating,
				resize: "horizontal",
				compact: false,
			},
			{
				name: "vendor_code",
				type: "TextInput",
				label: t("Wallet provider code"),
				isRequired: true,
				validation: [isRequired, maxLengthValidation(190)],
			},
			{
				label: t("Provider"),
				type: "TextInput",
				name: "provider",
				isRequired: true,
				validation: [isRequired, maxLengthValidation(60), isProviders],
			},
			{
				name: "daily_transaction_limit",
				type: "TextInput",
				label: t("Daily transaction limit"),
				validation: [onlyDigitsAllowed],
				existsIf: isInstance,
			},
			{
				label: t("Wallet off limit"),
				name: "wallet_off_limit",
				type: "TextInput",
				isRequired: (form) => {
					if (form?.wallet_type && isPSWithParsing(form.wallet_type)) {
						return true;
					} else {
						return undefined;
					}
				},
				validation: (v, form) => {
					if (form?.wallet_type && isPSWithParsing(form.wallet_type)) {
						return isRequired(v);
					} else undefined;
				},
				transform: (value: string) => value === "" ? undefined : Number(value),
			},
			{
				label: t("Daily limit on the amount"),
				name: "wallet_off_load",
				type: "TextInput",
				existsIf: isInstance,
				isRequired: (form) => {
					if (form?.wallet_type && !isPSWithParsing(form.wallet_type)) {
						return true;
					} else {
						return undefined;
					}
				},
				validation: (v, form) => {
					if (form?.wallet_type && !isPSWithParsing(form.wallet_type)) {
						return isRequired(v);
					} else undefined;
				},
				transform: (value: string) => Number(value),
			},
			{
				name: "parsing_start_at",
				label: t("Parsing start date"),
				placeholder: t("Wallet creation date"),
				type: "DatePicker",
				dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
				withTime: true,
				blockPredicate: isCreating ? notLaterThanToday : notLaterThanCreatedAt(created_at),
				customStyles: () => ({
					transform: `translate(115%, -103.5%)`,
				}),
				existsIf: ({ wallet_type }: any) => isInstance && isP2PWalletSelected(wallet_type),
				className: styles.picker,
			},
			{
				label: t("Displayed payment details"),
				name: "display_identifier",
				type: "TextInput",
				validation: [maxLengthValidation(40)],
			},
			{
				label: t("Proxy"),
				name: "proxies",
				type: "Proxies",
				isRequired: useProxy,
				validation: [isProxyRequired(useProxy)],
				existsIf: isInstance,
				proxiesList,
			},
		],
		[walletTypesLoading, walletTypes.length, walletTypesOptions.length, proxiesList.length, useProxy, t]
	);

	const commonWalletFields = useCommonWalletFields();

	const specificWalletFields = useSpecificWalletFields(isCreating);

	const fieldTree = new FieldTree(
		[
			...specificWalletFields.map((field) => ({
				...field,
				specific: true,
			})),
			...commonWalletFields,
		],
		formState
	);

	const sortedSpecificFields = fieldTree.breadthTraversal().filter((field) => field);

	return [
		{
			label: t("Wallet number"),
			name: "identifier",
			type: isCreating ? "TextInput" : "Hidden",
			isRequired: true,
			validation: isRequired,
		},
		{
			label: t("Type"),
			type: isCreating ? "Select" : "Hidden",
			name: "wallet_type",
			isRequired: true,
			validation: isRequired,
			options: walletTypesOptions,
			isLoading: walletTypesLoading,
		},
		...requisites.map(({ code, name }) => ({
			type: "TextInput",
			name: code,
			label: name,
			isRequired: true,
			validation: [isRequired],
		})),
		...sortedSpecificFields,
		...commonFields,
		{
			type: "Group",
			render: (children) => <div className={styles.modalActions}>{children}</div>,
			elements: [
				{
					name: "submit",
					type: "SubmitButton",
					label: isCreating ? t("Create") : t("Save"),
					dataTestId: isCreating ? "create_wallet" : "edit_wallet",
					onSubmit,
				},
			],
		},
	];
};

type UseWalletDataType = (
	isCreating: boolean,
	onSubmit: handleSuccessType,
	requisites: readonly Requisite[],
	useProxy: boolean,
	proxiesList: readonly ProxyType[],
	formState: any,
	created_at: string,
	adminPanelType: "instance" | "superadmin"
) => readonly ElementType<unknown>[]; // TODO: Leaving until the PayKitForm typing is fixed
