import API from "api";
import { PayKitForm } from "@paykassma/pay-kit";
import { Counterparty, WalletListItem, WalletPriority } from "api/walletGroup";
import { WalletTypesContext } from "contexts/WalletTypesContext";
import { WalletState } from "modules/Wallets/components/modals/ChangeWalletModal/utils/enums";
import { isPSWithParsing } from "modules/Wallets/components/modals/ChangeWalletModal/utils/paymentSystems";
import { WalletTypes } from "utils/enums";
import { useContext, useState } from "react";
import { isP2PWalletSelected } from "utils/isP2PWalletSelected";
import { isRequired } from "utils/validate";
import styles from "./EditCounterpartyModal.module.scss";
import { useAuthContext } from "contexts/AuthContext/AuthContext";
import { Roles } from "contexts/AuthContext/utils/enums";

type CounterpartyEditModalProps = {
	onSuccess: () => void;
	counterparty_id: Counterparty["counterparty_uuid"];
	wallet?: WalletListItem;
};

export const priorityMapping: Record<WalletPriority, string> = {
	[WalletPriority.LOW]: "Низкий",
	[WalletPriority.BELOW_NORMAL]: "Пониженный",
	[WalletPriority.NORMAL]: "Нормальный",
	[WalletPriority.ABOVE_NORMAL]: "Повышенный",
	[WalletPriority.HIGH]: "Высокий",
};

export const priorityOptions = Object.keys(priorityMapping).map((priority: string) => {
	//@ts-ignore
	return { value: +priority, label: priorityMapping[priority] };
});

const EditCounterpartyModal = ({ wallet, counterparty_id, onSuccess }: CounterpartyEditModalProps) => {
	const counterpartyData = wallet?.counterparties.find(
		(counterparty) => counterparty.counterparty_uuid === counterparty_id
	);

	const hasStatus = (status: WalletState) => counterpartyData?.counterparty_wallet_settings?.status.includes(status);

	const allowedBalanceExceeded = hasStatus(WalletState.ALLOWED_BALANCE_EXCEEDED);
	const dailyTransactionLimitExceeded = hasStatus(WalletState.DAILY_TRANSACTION_LIMIT_EXCEEDED);
	const dailyTurnoverLimitExceeded = hasStatus(WalletState.DAILY_TURNOVER_LIMIT_EXCEEDED);
	const monthTurnoverLimitExceeded = hasStatus(WalletState.MONTHLY_TURNOVER_LIMIT_EXCEEDED);

	const { hasRole } = useAuthContext();

	const hasWalletSettingsRole = hasRole(Roles.WALLET_SETTINGS);
	const hasAdminUpdateRole = hasRole(Roles.WALLET_UPDATE);

	const { walletTypes } = useContext(WalletTypesContext);

	const initData = {
		...(counterpartyData?.counterparty_wallet_settings || {}),
	};

	const formDataAPI = PayKitForm.useFormDataAPI({ initialState: initData });

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const submitHandler = (form: any) => {
		const copy = { ...form };

		if (copy.wallet_off_load) {
			copy.wallet_off_load = `${copy.wallet_off_load}`;
		} else {
			copy.wallet_off_load = null;
		}

		if (copy.daily_transaction_limit) {
			copy.daily_transaction_limit = parseInt(copy.daily_transaction_limit);
		}

		if (copy.daily_transaction_limit === null || copy.daily_transaction_limit === "") {
			delete copy.daily_transaction_limit;
		}

		// возможно, понадобится в следующих доработках:
		// if (copy.monthly_turnover_limit) {
		// 	copy.monthly_turnover_limit = `${copy.monthly_turnover_limit}`;
		// }

		setIsLoading(true);
		API.wallet
			.editCounterparty(copy)
			.then((resp) => {
				if (resp.status === "success") {
					window.pushAlert({
						type: "success",
						description: "Настройки контрагента сохранены",
					});

					onSuccess();
				} else {
					window.pushAlert({
						type: "error",
						title: "Ошибка при сохранении настроек контрагента",
						description: resp.error_message,
					});

					formDataAPI.setExternalErrors(parseAPIResponseErrorsFormat(resp.errors));
				}
			})
			.finally(() => setIsLoading(false));
	};

	const checkTheRightsForField = () => {
		if (hasWalletSettingsRole && hasAdminUpdateRole) {
			return false;
		}

		if (hasWalletSettingsRole && !hasAdminUpdateRole) {
			return true;
		}

		if (!hasWalletSettingsRole && hasAdminUpdateRole) {
			return false;
		}
	};

	if (!counterpartyData) {
		return <>Загрузка...</>;
	}

	const walletOffLoadIsRequired = !isPSWithParsing(wallet?.wallet_type_code as WalletTypes);

	const schema = [
		{
			type: "Hidden",
			name: "wallet_counterparty_settings_id",
		},
		{
			label: "Приоритет",
			type: "Select",
			name: "priority",
			options: priorityOptions,
			disabled: checkTheRightsForField(),
		},
		{
			type: "Group",
			elements: [
				{
					label: "Состояние",
					type: "Switcher",
					name: "is_active",
					disabled: checkTheRightsForField(),
					options: [
						{
							label: `Отключенный`,
							value: false,
						},
						{
							label: `Рабочий`,
							value: true,
						},
					],
				},
			],
			render: (children) => (
				<div className={[checkTheRightsForField() && styles.notAllowedWrapper].join(" ")}>
					<div className={[styles.childrenWrapper, checkTheRightsForField() && styles.notAllowed].join(" ")}>
						{children}
					</div>
				</div>
			),
		},
		{
			label: "Видимость",
			type: "Toggler",
			name: "plugin_status",
			disabled: (form) =>
				!form.is_active ||
				allowedBalanceExceeded ||
				dailyTransactionLimitExceeded ||
				dailyTurnoverLimitExceeded ||
				monthTurnoverLimitExceeded ||
				!hasWalletSettingsRole,
		},
		{
			name: "parsing_start_at",
			type: "DatePicker",
			label: "Дата начала парсинга",
			dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
			blockPredicate: (date) => date < new Date(wallet.created_at),
			existsIf: (form: any) => isP2PWalletSelected(walletTypes, wallet?.wallet_type_code),
			withTime: true,
			disabled: checkTheRightsForField(),
		},
		{
			type: "Group",
			render: (children) => {
				return (
					<div className={styles.limits}>
						<h4>Лимиты</h4>
						{children}
					</div>
				);
			},
			elements: [
				{
					label: "Суточный по сумме",
					name: "wallet_off_load",
					type: "TextInput",
					isRequired: walletOffLoadIsRequired,
					disabled: checkTheRightsForField(),
					validation: [
						(value) => {
							if (walletOffLoadIsRequired) {
								if (!value) {
									return isRequired(value);
								}

								return validateFloatNumber(value);
							}

							return undefined;
						},
					],
				},
				{
					label: "Суточный по транзакциям",
					name: "daily_transaction_limit",
					type: "TextInput",
					validation: [skipValidationIfEmpty(validatePositiveInteger)],
					disabled: checkTheRightsForField(),
				},
				//
				// возможно, понадобится в следующих доработках:
				// {
				// 	label: "Месячный по сумме",
				// 	name: "monthly_turnover_limit",
				// 	type: "TextInput",
				// 	validation: [validateFloatNumber],
				// },
			],
		},
		{
			type: "Group",
			render: (children: React.ReactElement) => {
				return <div className={styles.actions}>{children}</div>;
			},
			elements: [
				{
					type: "SubmitButton",
					label: "Сохранить",
					isLoading,
					onSubmit: submitHandler,
				},
			],
		},
	];

	return <PayKitForm.Builder schema={schema} formDataAPI={formDataAPI} />;
};

export default EditCounterpartyModal;

// TODO: move to utils
const validateFloatNumber = (input: string) => {
	const numberPattern = /^\d+(\.\d+)?$/;

	if (numberPattern.test(input)) {
		return undefined;
	} else {
		return "Допустимы только целые и дробные числа";
	}
};

const validatePositiveInteger = (input: string) => {
	const integerPattern = /^\d+$/;

	if (integerPattern.test(input)) {
		return undefined;
	} else {
		return "Допустимы только целые числа";
	}
};

const skipValidationIfEmpty = (func) => {
	return (value: null | undefined | "" | number) => {
		if (!value && value !== 0) {
			return undefined;
		} else {
			return func(value);
		}
	};
};

type ExternalErrorType = {
	message: string;
	code: number;
	target: string;
};

const parseAPIResponseErrorsFormat = (externalErrors: readonly ExternalErrorType[]) => {
	const errors: Record<string, string> = {};

	try {
		externalErrors.forEach(({ target, message }) => {
			errors[target] = message;
		});
	} catch (err) {
		console.error("An unexpected field format error occurred when attempting to create/edit a wallet");
	}

	return errors;
};
