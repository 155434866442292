import { ModalProps } from "utils/types";
import { WalletListItem } from "api/walletGroup";
import { OldResponse } from "api/types";
import styles from "./RecoverWalletModal.module.scss";
import { PayKitForm } from "@paykassma/pay-kit";

type RecoverWalletModalProps = ModalProps & {
	readonly id: WalletListItem[`id`];
	readonly actions: {
		readonly recoverWallet: (
			id: WalletListItem[`id`],
			body: { parsing_start_at?: string }
		) => Promise<OldResponse<void>>;
	};
};

export const RecoverWalletModal = ({ closeModal, id, actions }: RecoverWalletModalProps) => {
	const handleSubmit = (form: { parsing_start_at?: string }) => {
		actions.recoverWallet(id, form).then((resp) => {
			if (resp.status === "success") {
				window.pushAlert({
					type: "success",
					title: "Успех",
					description: `Кошелек ${id} успешно восстановлен`,
				});

				closeModal && closeModal();
			}

			if (resp.status === "error") {
				window.pushAlert({
					type: "error",
					title: "Ошибка",
					description: resp.error_message,
				});
			}

		});
	};

	const schema = [
		{
			name: "parsing_start_at",
			type: "DatePicker",
			label: "Дата начала парсинга",
			dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
			customStyles: () => ({
				top: "unset",
				transform: "translate(117%, -61%)",
			}),
			withTime: true,
		},
		{
			type: "SubmitButton",
			label: "Далее",
			onSubmit: handleSubmit,
		},
	];

	return (
		<>
			<div className={styles.content}>
				<p>
					Восстановить кошелек <b>{id}</b>?
				</p>
				<p>Если кошелек p2p , укажите дату начала парсинга транзакций</p>
			</div>

			<PayKitForm.Builder schema={schema} />
		</>
	);
	// return (
	// 	<DeleteModal
	// 		closeModal={closeModal}
	// 		btnClassname={styles.successBtn}
	// 		text={
	// 			<>
	// 				Восстановить кошелек <b>{id}</b>?
	// 				Если кошелек p2p , укажите дату начала парсинга транзакций
	// 			</>
	// 		}
	// 		deleteLabel="Восстановить"
	// 		actions={{
	// 			onDelete: () => {
	// 				return actions.recoverWallet(id).then(() => {
	// 					window.pushAlert({
	// 						type: "success",
	// 						title: "Успех",
	// 						description: `Кошелек ${id} успешно восстановлен`,
	// 					});
	// 				});
	// 			}
	// 		}}
	// 	/>
	// );
};
