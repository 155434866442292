import { fetchApi } from "api/fetch";
import { ProxyCommonType } from "modules/Proxies/components/ProxyModalRows";

const proxiesGroup = {
	getProxies: (filters: any) => fetchApi<GetProxiesType>({ url: "/proxy", params: filters }),
	initCheckingMultiAddingProxies: (params: readonly InitCheckingMultiAddingProxiesType[]) => fetchApi<void>({ url: "proxy/check-by-proxy", params, method: "POST" }),
	checkAllMultiAddingProxyStatuses: () => fetchApi<CheckAllMultiAddingProxyStatusesType>({ url: "proxy/result-check-by-proxy" }),
	initCheckingMultiAddingProxy: (params: InitCheckingMultiAddingProxiesType) => fetchApi<void>({ url: "proxy/init-checking", params, method: "POST" }),
	createProxies: (params: AddProxiesType) => fetchApi<void>({ url: "proxy/multiple-create", params, method: "POST" }),
	createProxy: (params: Partial<ProxyCommonType>) => fetchApi<void>({ url: "proxy/create", params, method: "POST" }),
	multipleUpdateProxy: (params: any) => fetchApi<void>({ url: "proxy/multiple-update", params, method: "PUT" }),
	initCheckAll: () => fetchApi<void>({ url: "proxy/initcheckall" }),
	getChecked: () => fetchApi<GetCheckedType>({ url: "proxy/getchecked" }),
	checkProxy: (uuid: string[]) => fetchApi<void>({ url: `proxy/check-by-uuid`, params: { uuids: uuid }, method: "POST" }),
	resultCheckByUuid: () => fetchApi<CheckProxyByUUIDData>({ url: "proxy/result-check-by-uuid" }),
	deleteProxy: (params: DeleteProxyType) => fetchApi<void>({ url: "proxy", params, method: "DELETE" }),
	loadProxyItem: (id: string) => fetchApi<LoadProxyItemType>({ url: `proxy/${id}` }),
	updateProxy: (params: UpdateProxyType, id: number) => fetchApi<void>({ url: `proxy/update/${id}`, params, method: "PUT" }),
	attachWallets: (wallet_hash_ids: string[], proxy_uuid: string) => fetchApi({ url: `proxy/attach-wallets`, params: { proxy_uuid, wallet_hash_ids }, method: `POST` }),
	detachWallets: (wallet_hash_ids: string[], proxy_uuid: string) => fetchApi({ url: `proxy/detach-wallets`, params: { proxy_uuid, wallet_hash_ids }, method: `POST` }),
};

export default proxiesGroup;

export type ProxyItemWalletsType = {
	readonly id: number;
	readonly identifier: string;
	readonly parser_type?: string | null;
};

export type LoadProxyItemType = {
	readonly proxy: {
		readonly country_code: string;
		readonly created_at: string;
		readonly flag: number;
		readonly id: string;
		readonly ip: string;
		readonly is_checking: number;
		readonly password: string;
		readonly port: number;
		readonly status: 0 | 1;
		readonly type: readonly ("socks4" | "socks5" | "https" | "http")[];
		readonly updated_at: string;
		readonly username: string;
		readonly wallets: readonly ProxyItemWalletsType[];
	};
};

export type UpdateProxyWalletsType = {
	readonly id: number | string;
	readonly identifier?: string;
	readonly parser_type?: string | null;
};

export type UpdateProxyType = {
	readonly country_code: string;
	readonly ip: string;
	readonly password: string;
	readonly port: number;
	readonly type: readonly ("socks4" | "socks5" | "https" | "http")[];
	readonly username: string;
	readonly wallets: readonly UpdateProxyWalletsType[];
};

export type DeleteProxyType = { readonly uuids: readonly string[] };

export type GetCheckedType = {
	readonly checked: boolean;
	readonly proxies: readonly ProxiesType[];
};

export type AddProxiesType = {
	readonly items: readonly CheckAllMultiAddingProxyType[];
};

export type CheckAllMultiAddingProxyStatusesType = {
	readonly status: boolean;
	readonly total: number;
	readonly verified: number;
	readonly proxies: readonly CheckAllMultiAddingProxyType[];
};

export type CheckAllMultiAddingProxyType = {
	readonly country_code: string;
	readonly in_system: boolean;
	readonly ip: string;
	readonly password: string;
	readonly port: string;
	readonly status: boolean;
	readonly type: string;
	readonly username: string;
	readonly uuid: string;
};

export type InitCheckingMultiAddingProxiesType = {
	readonly country_code: string;
	readonly ip_host: string;
	readonly password: string;
	readonly port: string;
	readonly type: string;
	readonly username: string;
};

export type ProxiesType = {
	readonly country_code: string;
	readonly created_at: string;
	readonly id: string;
	readonly ip: string;
	readonly is_checking: boolean;
	readonly last_checked_at: null | string;
	readonly password: string;
	readonly port: number;
	readonly status: boolean;
	readonly type: string;
	readonly updates_at: string;
	readonly username: string;
	readonly wallets: { hash_id: string; identifier: string; wallet_type_code: string }[];
};

export type GetProxiesType = ProxiesType[];

export type CheckProxyByUUIDData = {
	data: {
		status: boolean;
		verified: number;
		total: number;
		proxies: {
			uuid: string;
			in_system: boolean;
			status: boolean;
			is_checking: boolean;
			last_checked_at: null | string;
		}[];
	};
};
