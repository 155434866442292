import Api from "api";
import {
	AddProxiesType,
	CheckAllMultiAddingProxyType,
	InitCheckingMultiAddingProxiesType
} from "api/proxiesGroup";
import ProxyModalContentManager from "modules/Proxies/components/AddProxy";
import { ProxyCommonType } from "modules/Proxies/components/ProxyModalRows";
import { setTimeoutPromise } from "modules/Proxies/contexts/helpers";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import { constructMultiAddingProxiesList } from "modules/Proxies/helpers";
import { Modal } from "pay-kit";
import { createContext, SetStateAction, useCallback, useContext, useState } from "react";
import { Dispatch } from "react";
import { uniqueID } from "utils/uniqueID";

import styles from "./addingProxiesContext.module.scss";

const AddingProxiesContext = createContext<ProxiesContextType>({} as ProxiesContextType);

const AddingProxiesContextProvider = ({ children }: { readonly children: JSX.Element }) => {
	const [error, setError] = useState<Error | null>(null);
	const [isAddProxyLoading, setIsAddProxyLoading] = useState(false);
	const [isAddingProxyStatuses, setIsAddingProxyStatuses] = useState(false);
	const [isProxyAddingLoader, setIsProxyAddingLoader] = useState(false);
	const [isUploadedProxiesListModal, setIsUploadedProxiesListModal] = useState(false);
	const [multiAddingProxies, setMultiAddingProxies] = useState<readonly MultiAddingProxiesType[] | null>(null);
	const [reservedMultiAddingProxies, setReservedMultiAddingProxies] = useState<readonly MultiAddingProxiesType[] | null>(null);
	const [proxyListValue, setProxyListValue] = useState("");
	const proxiesList = constructMultiAddingProxiesList(proxyListValue);
	const [isProxiesOpen, setIsProxiesOpen] = useState<{
		readonly isAddProxyOpen: boolean,
		readonly isUploadedProxiesOpen: boolean
	}>({ isAddProxyOpen: false, isUploadedProxiesOpen: false });
	const [isRecheckingMultiAddingProxiesLoading, setIsRecheckingMultiAddingProxies] = useState(false);
	const [isCreateProxyLoading, setIsCreateProxyLoading] = useState(false);

	const { getProxies } = useProxyContext();

	const initForm = {} as ProxyCommonType;

	const closeProxyModal = useCallback(() => {
		setIsAddProxyLoading(false);

		setIsProxyAddingLoader(false);
		setIsUploadedProxiesListModal(false);
		setIsAddingProxyStatuses(false);
		setIsProxiesOpen({ isUploadedProxiesOpen: false, isAddProxyOpen: false });
		setMultiAddingProxies(null);
		setReservedMultiAddingProxies(null);
	}, []);

	const checkAllMultiAddingProxyStatuses = (successMessage?: string) => {
		setIsAddingProxyStatuses(true);
		setError(null);

		Api.proxy.checkAllMultiAddingProxyStatuses().then(res => {
			if (res.status === "success") {
				const data = res.data;
				if (data.status) {
					setIsRecheckingMultiAddingProxies(false);
	
					setIsAddingProxyStatuses(false);
					setIsProxyAddingLoader(false);
					setIsUploadedProxiesListModal(true);
	
					if (data.verified >= 1) {
						const expandedProxies = data.proxies.map((elem) => ({ id: uniqueID(), ...elem }));
	
						setMultiAddingProxies(expandedProxies);
						setReservedMultiAddingProxies(expandedProxies);
	
						if (successMessage) {
							window.pushAlert({
								description: successMessage,
								type: "success"
							});
						}
	
					}
				} else {
					setTimeoutPromise({
						milliseconds: 5000,
						delayedMethod: () => checkAllMultiAddingProxyStatuses(successMessage)
					});
				}
			} else {
				setIsRecheckingMultiAddingProxies(false);
				setIsAddingProxyStatuses(false);
				setIsProxyAddingLoader(false);
				setIsUploadedProxiesListModal(false);
			}
		}).catch((err) => {
			console.log(err);
			setIsRecheckingMultiAddingProxies(false);

			setIsAddingProxyStatuses(false);
			setIsProxyAddingLoader(false);
			setIsUploadedProxiesListModal(false);
			setError(err);
		});
	};

	const initCheckingMultiAddingProxies = (params: readonly InitCheckingMultiAddingProxiesType[]) => {
		setIsAddingProxyStatuses(true);
		setIsProxyAddingLoader(true);
		setError(null);

		const items = params.map((param) => ({...param, ip: param.ip_host, port: +param.port}))

		Api.proxy.initCheckingMultiAddingProxies({items}).then(
			res => {
				if (res.status === "success") {
					checkAllMultiAddingProxyStatuses();
				}
				if (res.status === "error") {
					closeProxyModal();
					window.pushAlert({
						description: res.error_message,
						type: "error"
					});
				}
			}
		).catch((err) => {
			console.log(err);
			setError(err);
			setIsAddingProxyStatuses(false);
			setIsProxyAddingLoader(true);
		}
		);
	};

	const initRecheckingMultiAddingProxies = (params: readonly InitCheckingMultiAddingProxiesType[], successMessage: string) => {
		setIsRecheckingMultiAddingProxies(true);
		setError(null);

		Api.proxy.initCheckingMultiAddingProxies({items: params}).then(
			res => {
				if (res.status === "success") {
					checkAllMultiAddingProxyStatuses(successMessage);
				}
			}
		).catch((err) => {
			console.log(err);
			setError(err);
			setIsRecheckingMultiAddingProxies(false);
		}
		);
	};

	const addProxies = async (params: AddProxiesType, updateExisted: boolean) => {
		setError(null);
		closeProxyModal();

		const newProxies = {
			...params,
			items: params.items.filter(proxy => !proxy.in_system),
		}

		const existedProxies = {
			...params,
			items: params.items.filter(proxy => proxy.in_system),
		}

		const promises: Promise<any>[] = [];
		
		if (newProxies.items.length > 0) {
			promises.push(
				Api.proxy.createProxies(newProxies)
			);
		}

		if (updateExisted && existedProxies.items.length > 0) {
			promises.push(
				Api.proxy.multipleUpdateProxy(existedProxies)
			);
		}

		return Promise.all(promises).then(resps => {
			const res = resps[0];
			if (res.status === "success") {
				window.pushAlert({
					description: `Успешно обновлено!`,
					type: "success"
				});
			}

			if (res.status === "error") {
				window.pushAlert({
					description: typeof res.message === "object" ?
						JSON.stringify(res.message) :
						res.message,
					type: "error"
				});
			}
		}).catch((err) => {
			console.log(err);
			setError(err);
		});
	};

	const createProxy = (params: Partial<ProxyCommonType>) => {
		setIsCreateProxyLoading(true);
		setError(null);

		Api.proxy.createProxy(params)
		.then(
			res => {
				if (res.status === "success") {
					getProxies();

					window.pushAlert({
						description: `Прокси успешно созданы`,
						type: "success"
					});

					closeProxyModal();
				} else {
					window.pushAlert({
						description: res.error_message,
						type: "error"
					});

					if (res.errors && res.errors.length > 0) {
						res.errors.forEach((error) => window.pushAlert({ description: error.message, type: "error" }))
					}
				}

				setIsCreateProxyLoading(false);
			}
		)
		.catch((err) => {
			console.log(err);
			setError(err);
		})
		.finally(() => {
			setIsCreateProxyLoading(false);
		});
	};

	const contextValue = {
		createProxy,
		addProxies,
		initRecheckingMultiAddingProxies,
		initCheckingMultiAddingProxies,
		closeProxyModal,
		setIsProxiesOpen,
		openAddProxyModal: setIsAddProxyLoading,
		setIsUploadedProxiesListModal,
		setMultiAddingProxies,
		multiAddingProxies,
		setReservedMultiAddingProxies,
		reservedMultiAddingProxies,
		proxiesList,
		setProxyListValue,
		proxyListValue,
		initForm,
		isCreateProxyLoading,
		isRecheckingMultiAddingProxiesLoading,
		isAddingProxyStatuses
	};

	const isExtendedUploadedProxiesListModal = isUploadedProxiesListModal && isProxiesOpen.isUploadedProxiesOpen && isProxiesOpen.isAddProxyOpen;

	let addProxyStyle = "";

	if (isProxyAddingLoader) {
		addProxyStyle = styles.proxyLoaderModal;
	}
	if (isExtendedUploadedProxiesListModal) {
		addProxyStyle = styles.UploadedProxiesListTableModal;
	}

	return (
		<AddingProxiesContext.Provider value={contextValue}>
			{children}

			<Modal
				className={addProxyStyle}
				title="Добавление прокси"
				onClose={closeProxyModal}
				isOpen={isAddProxyLoading}
			>
				<ProxyModalContentManager
					isProxyAddingLoaderLoading={isProxyAddingLoader}
					isUploadedProxiesListLoading={isExtendedUploadedProxiesListModal}
				/>
			</Modal>

		</AddingProxiesContext.Provider>
	);
};

export default AddingProxiesContextProvider;

export const useAddingProxiesContext = () => useContext(AddingProxiesContext);

export type ProxiesContextType = {
	readonly createProxy: (params: Partial<ProxyCommonType>) => void
	readonly addProxies: (params: AddProxiesType, updateExisted: boolean) => Promise<void>,
	readonly initRecheckingMultiAddingProxies: (params: readonly InitCheckingMultiAddingProxiesType[], successMessage: string) => void
	readonly initCheckingMultiAddingProxies: (params: readonly InitCheckingMultiAddingProxiesType[]) => void
	readonly closeProxyModal: () => void
	readonly setIsProxiesOpen: Dispatch<SetStateAction<{
		readonly isAddProxyOpen: boolean,
		readonly isUploadedProxiesOpen: boolean
	}>>
	readonly openAddProxyModal: Dispatch<boolean>
	readonly setIsUploadedProxiesListModal: Dispatch<SetStateAction<boolean>>
	readonly setMultiAddingProxies: Dispatch<SetStateAction<readonly MultiAddingProxiesType[] | null>>
	readonly multiAddingProxies: readonly MultiAddingProxiesType[] | null
	readonly setReservedMultiAddingProxies: Dispatch<SetStateAction<readonly MultiAddingProxiesType[] | null>>
	readonly reservedMultiAddingProxies: readonly MultiAddingProxiesType[] | null
	readonly proxiesList: readonly proxiesListElemType[]
	readonly setProxyListValue: Dispatch<SetStateAction<string>>
	readonly proxyListValue: string
	readonly initForm: ProxyCommonType,
	readonly isCreateProxyLoading: boolean
	readonly isRecheckingMultiAddingProxiesLoading: boolean
	readonly isAddingProxyStatuses: boolean
}

export type MultiAddingProxiesType = CheckAllMultiAddingProxyType & {
	readonly id: string,
	readonly isChecking?: boolean
}

export type proxiesListElemType = {
	readonly proxy: InitCheckingMultiAddingProxiesType,
	readonly isFormatValid: boolean
	readonly inputValue: string
}
