import { ValueCast } from "api/hiddenGroup"
import { isJSON, isRequired, isValidNumber } from "utils/validate"
import styles from './hiddenStyles.module.scss';
import { Button, Tooltip } from "@paykassma/pay-kit";
import { TrashIcon } from "components/Icons/TrashIcon/TrashIcon";
import { PlusIcon } from "components/Icons/PlusIcon/PlusIcon";
import QuestionCircle from "./Settings/assets/questionIcon.svg";

export const getInputTypeByCast = (cast: ValueCast | string | null): string => {
    switch (cast) {
        // case "integer":
        //     return "NumberInput"
        case ValueCast.JSON:
            return "Textarea"
        case "json":
            return "Textarea"
        case ValueCast.BOOLEAN:
            return "Toggler"
        case "boolean":
            return "Toggler"
        default:
            return "TextInput"
    }
}

const isNotRequiredField = (field: string) => {
    const fieldNames = [
        "NN_WALLETS_LIMIT_CHAT_ID",
        "NN_NEW_TRANSACTION_CHAT_ID",
        "NN_WALLETS_VISABILITY_CHAT_ID",
        "NN_WALLETS_CHECK_CHAT_ID",
        "PLUGINAPI_IP_LIST",
    ];

    return fieldNames.includes(field);
};

export const getValidationByCast = (cast: ValueCast | string | null, field?: string) => {
    const validationFuncs = [];
    if (field === "FAKE_TRANSACTION_CONTROL") {
        validationFuncs.push(isRequired);
        return validationFuncs
    }

    if (field && !isNotRequiredField(field) && cast !== "boolean") {
        validationFuncs.push(isRequired);
    }

    switch (cast) {
        case ValueCast.JSON:
            validationFuncs.push(isJSON)
            break;
        case ValueCast.INTEGER:
            validationFuncs.push(isValidNumber)
            break
        default:
            break
    }

    return validationFuncs;
}

export const getValueArrayField = (label: string, desc: string = "") => {
    return {
        type: "Repeat",
        render: (children, { insertItem, error }) => (
            <>
                <div className={styles.valueHeading}>
                    <div className={styles.valueLabel}>
                        {desc ? (
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                            }}>
                                <div>
                                    {label}
                                </div>
                                <Tooltip tip={desc}>
                                    <QuestionCircle />
                                </Tooltip>
                            </div>
                        ) : (
                            <div>
                                {label}
                            </div>
                        )}
                    </div>
                    <Button
                        data-testid="add-value"
                        variant="text-secondary"
                        onClick={() => insertItem({ value: undefined }, "insertAfter")}
                    >
                        <PlusIcon className={styles.addIcon} />
                    </Button>
                </div>
                {error && <div style={{ color: "red" }}>{error}</div>}
                {children}
            </>
        ),
        elements: [
            {
                type: "Group",
                render: (children, { removeCurrentItem }) => (
                    <div className={styles.valueField}>
                        {children}
                        <Button data-testid="removeValue" variant="text-secondary" onClick={removeCurrentItem}>
                            <TrashIcon />
                        </Button>
                    </div>
                ),
                elements: [
                    {
                        name: "value",
                        type: "TextInput",
                        validation: (form) => {
                            if (isNotRequiredField(label)) {
                                return undefined
                            }
                            return isRequired(form)
                        },
                    },
                ],
            },
        ],
    }
}

export const getValueField = (cast: ValueCast | null, label = "Значение") => {
    const commonProps = {
        name: "value",
        label,
        isRequired: cast !== ValueCast.BOOLEAN,
        existsIf: !!cast,
    }

    return cast === ValueCast.ARRAY ? {
        ...commonProps,
        ...getValueArrayField(label),
    } : {
        ...commonProps,
        type: getInputTypeByCast(cast),
        validation: getValidationByCast(cast),
    }
}