import { PayKitForm } from "@paykassma/pay-kit";
import InputFormatHint from "modules/Proxies/components/InputFormatHint";
import { ProxyCommonType } from "modules/Proxies/components/ProxyModalRows";
import { proxiesListElemType, useAddingProxiesContext } from "modules/Proxies/contexts/AddingProxiesContext";
import { useProxyContext } from "modules/Proxies/contexts/ProxyContext";
import {
	checkedStatuses,
	isIPHOSTRequired,
	isIPHOSTValid,
	isPortValid,
	isRequired,
	proxyTypes,
} from "modules/Proxies/data";
import { constructMultiAddingProxiesList } from "modules/Proxies/helpers";
import { ScrollBox } from "pay-kit";
import { ReactElement, useEffect, useState } from "react";

import styles from "./addProxyModal.module.scss";

const AddProxy = () => {
	const { setIsProxiesOpen, initCheckingMultiAddingProxies, createProxy, isCreateProxyLoading } =
		useAddingProxiesContext();
	const { countryList } = useProxyContext();
	const [proxyCheckingStatus, setProxyCheckingStatus] = useState<ProxyCheckingStatusType>(checkedStatuses.NOT_CHECKED);
	const [proxiesList, setProxiesList] = useState<readonly proxiesListElemType[]>([]);

	const countryOptions = countryList?.map((elem) => ({ label: elem.code, value: elem.code })) || [];
	const typeOptions = proxyTypes.map((elem) => ({ label: elem, value: elem }));

	const onStateChange = (prevstate: formStateType, currState: formStateType) => {
		const value = currState.creating_by_list_form?.proxies_list || "";
		setProxiesList(constructMultiAddingProxiesList(value));
	};

	const onListSubmit = () => {
		setProxyCheckingStatus(() => {
			const isValid = proxiesList.some((elem) => elem.isFormatValid);
			if (isValid) return checkedStatuses.WORKING;
			else return checkedStatuses.NOT_WORKING;
		});
	};

	const onManualListSubmit = (formData: formStateType) => {
		const reqData = {
			...formData.manual_creating_form,
			port: parseInt(formData.manual_creating_form.port as string),
		};

		createProxy(reqData as Partial<ProxyCommonType>);
	};

	useEffect(() => {
		if (proxyCheckingStatus === checkedStatuses.WORKING) {
			setIsProxiesOpen((proxiesOpen) => ({ ...proxiesOpen, isUploadedProxiesOpen: true }));
			const proxies = proxiesList.filter((elem) => elem.isFormatValid).map(({ proxy }) => proxy);

			initCheckingMultiAddingProxies(proxies);
		}
	}, [proxyCheckingStatus]);

	const SCHEMA: any = [
		{
			type: "Group",
			render: (element: ReactElement) => (
				<ScrollBox scrollDirection={"vertical"} className={styles.scrollBox}>
					{element}
				</ScrollBox>
			),
			elements: [
				{
					type: "Toggler",
					name: "list_mode_on",
					label: "Загрузить списком",
				},
				{
					type: "Group",
					name: "creating_by_list_form",
					render: (element: ReactElement) => (
						<InputFormatHint proxyCheckingStatus={proxyCheckingStatus}>{element}</InputFormatHint>
					),
					existsIf: ({ list_mode_on }: formStateType) => list_mode_on === true,
					elements: [
						{
							type: "Textarea",
							name: "proxies_list",
							placeholder: "Список прокси",
							rows: 5,
							className: styles.textArea,
						},
						{
							type: "Group",
							render: (element: ReactElement) => <div className={styles.submitButton}>{element}</div>,
							elements: [
								{
									name: "submit",
									onSubmit: onListSubmit,
									type: "SubmitButton",
									disabled: ({ creating_by_list_form }: formStateType) => !creating_by_list_form?.proxies_list,
									label: "Проверить список",
								},
							],
						},
					],
				},
				{
					type: "Group",
					name: "manual_creating_form",
					existsIf: ({ list_mode_on }: formStateType) => !list_mode_on,
					elements: [
						{
							type: "TextInput",
							name: "ip",
							label: "IP|HOST",
							isRequired: true,
							validation: [isIPHOSTRequired, isIPHOSTValid],
							className: styles.ere,
						},
						{
							type: "TextInput",
							name: "port",
							label: "Порт",
							isRequired: true,
							validation: [isRequired, isPortValid],
						},
						{
							type: "TextInput",
							name: "username",
							label: "Логин",
							isRequired: true,
							validation: [isRequired],
						},
						{
							type: "TextInput",
							name: "password",
							label: "Пароль",
							isRequired: true,
							htmlType: "password",
							validation: [isRequired],
						},
						{
							type: "Select",
							name: "type",
							label: "Тип",
							options: typeOptions,
							isRequired: true,
							validation: [isRequired],
						},
						{
							type: "Select",
							name: "country_code",
							label: "Страна",
							options: countryOptions,
							isRequired: true,
							validation: [isRequired],
						},
					],
				},
			],
		},
		{
			type: "Group",
			render: (element: ReactElement) => <div className={styles.submitButton}>{element}</div>,
			existsIf: ({ list_mode_on }: formStateType) => !list_mode_on,
			elements: [
				{
					name: "submit",
					type: "SubmitButton",
					label: "Создать",
					onSubmit: onManualListSubmit,
					isLoading: isCreateProxyLoading,
				},
			],
		},
	];

	const AddProxyModalWrapperStyle = isCreateProxyLoading
		? [styles.AddProxyModalWrapper, styles.disableContent].join(" ")
		: styles.AddProxyModalWrapper;

	return (
		<div className={AddProxyModalWrapperStyle}>
			<PayKitForm.Builder schema={SCHEMA} onStateChange={onStateChange} />
		</div>
	);
};

export default AddProxy;

export type formStateType = {
	readonly list_mode_on?: boolean;
	readonly creating_by_list_form?: {
		readonly proxies_list: string;
	};
	readonly manual_creating_form: Partial<{
		readonly ip: string;
		readonly port: string;
		readonly username: string;
		readonly password: string;
		readonly type: typeof proxyTypes;
		readonly country_code: string;
		readonly wallets: readonly {
			readonly type: number;
		}[];
	}>;
};

export type ProxyCheckingStatusType = "WORKING" | "NOT_WORKING" | "NOT_CHECKED";
